<!--
 * @Description: 目录树
 * @LastEditors: LS
 * @Date: 2021-04-21 15:15:39
-->
<template>
  <div class="directory">
    <div class="fixed g-ct-cn">
      <div class="label-wrap">
        <HeadTitle>
          <TabList
            defaultActiveTab="picture"
            @changeActiveTab="clickTab"
            :tabList="[{value: 'picture', label: '图片列表'},{value: 'table', label: '表格列表'}]"
          ></TabList>
        </HeadTitle>
      </div>
      <div class="tree">
        <TreeList
          :activeTab="activeTab"
          @click-img-dir="clickImgDir"
        ></TreeList>
      </div>
    </div>
  </div>
</template>

<script>
import { PdfBus } from '@dl-components/pdf-view/dist/index.js'
import TreeList from './tree.vue'
import { TabList, HeadTitle } from '@/components'

export default {
  name: 'pdf-directory',
  components: {
    TreeList,
    HeadTitle,
    TabList
  },
  props: {
    pdfRendered: Boolean
  },
  data() {
    return {
      activeTab: 'picture',
      pdfViewInstance: null,
    }
  },
  mounted() {
    PdfBus.on('pdf-rendered', ({ pdfViewInstance }) => {
      this.pdfViewInstance = pdfViewInstance
    })
  },
  methods: {
    addHighDom({boxArr, currentPage}) {
      const pageView = this.pdfViewInstance.pdfViewer.getPageView(currentPage - 1)
      const screenRect = pageView.viewport.convertToViewportRectangle(boxArr)

      const x = Math.min(screenRect[0], screenRect[2]); const width = Math.abs(screenRect[0] - screenRect[2])
      const y = Math.min(screenRect[1], screenRect[3]); const height = Math.abs(screenRect[1] - screenRect[3])

      const overlayDiv = document.createElement('div')
      overlayDiv.setAttribute('style', 'background-color: rgb(73 133 255 / 40%);position:absolute;' +
  'left:' + x + 'px;top:' + y + 'px;width:' + width + 'px;height:' + height + 'px;')

      // 第一次进来可能还没有pageView.div，所以需要setTimeout
      setTimeout(() => {
        pageView.div.appendChild(overlayDiv)
        // 只是先覆盖一层背景图，然后再消失
        setTimeout(() => {
          pageView.div.removeChild(overlayDiv)
        }, 800)
      }, 0)
    },
    scrollToPdfDetial(locations) {
      if (!this.pdfViewInstance) {
        this.$message.error('pdf预览器还没有加载好')
        return
      }
      const { bbox, page_no: currentPage } = locations // 后端得到位置信息
      if (currentPage < 0) {
        return
      }
      const boxArr = bbox.slice(1, bbox.length - 1).split('#')
      const pageView = this.pdfViewInstance.pdfViewer.getPageView(currentPage - 1)
      const screenRect = pageView.viewport.convertToViewportRectangle(boxArr)
      const y = Math.min(screenRect[1], screenRect[3])
      // TODO： 这里需要优化，最好能让他居zhong
      this.pdfViewInstance.setPage(Number(currentPage), y - 100)
      this.addHighDom({boxArr, currentPage})
    },
    clickImgDir(item) {
      this.scrollToPdfDetial({bbox: item.pdf_location, page_no: item.pdf_page})
    },
    clickTab(tabName) {
      this.activeTab = tabName
    }
  },
  watch: {
    pdfRendered(rendered) {
      if (rendered) {
        const query = this.$route.query
        if (query.page) {
          this.scrollToPdfDetial({bbox: query.location, page_no: query.page})
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.directory {
  // 占一个位置
  width: 320px;
  .fixed {
    display: flex;
    flex-direction: column;
    width: 320px;
    position: fixed;
    padding: 20px 15px;
    box-sizing: border-box;
    max-height: calc(100vh - 160px);
  }
  .label-wrap {
    margin-left: -4px;
  }
  .tree {
    flex: 1;
    overflow: auto;
    padding: 10px 0 0;
  }
}
</style>
