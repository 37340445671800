<template>
  <div>
    <el-timeline
      class="tree-line"
      v-if="listObj[activeTab] && listObj[activeTab].length > 0"
    >
      <el-timeline-item
        v-for="(item) in (listObj[activeTab] || [])"
        :key="item.id"
        :type="curID === item.id ? 'primary' : ''"
        :class="{'high-item': curID === item.id, 'tree-item': true}"
      >
        <!-- <el-tooltip
        class="item"
        effect="dark"
        placement="left-start"
      >
        <div slot="content">{{item.name}}</div>
        <div
          class="text f-ellipsis1"
          @click="clickItem(item)"
        >{{item.name}}</div>
      </el-tooltip> -->
        <div
          class="text f-ellipsis1"
          @click="clickItem(item)"
        >{{item.name}}</div>
      </el-timeline-item>
    </el-timeline>
    <div
      class="tip"
      v-else-if="listObj[activeTab]"
    >
      暂无数据
    </div>
  </div>
</template>

<script>
import { request } from '@/utils'
export default {
  components: {},
  props: {
    activeTab: String,
  },
  data () {
    return {
      listObj: {
        picture: null,
        table: null,
      },
      curID: -1,
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      const list = this.listObj[this.activeTab]
      if (!list) {
        request({
          url: `/irapi/report/${this.activeTab}`,
          method: 'get',
          params: {
            report_id: this.query.id,
          }
        }).then((res) => {
          this.listObj[this.activeTab] = res.data.datas || []
          // 将默认高亮的Item滚动到视觉中央
          this.$nextTick(() => {
            const highDOM = this.$el.querySelector('.high-item')
            highDOM && highDOM.scrollIntoView({block: 'center'})
          })
        })
      }
    },
    clickItem(item) {
      this.curID = item.id
      this.$emit('click-img-dir', item)
    },
  },
  watch: {
    activeTab() {
      this.curID = -1
      this.getList()
    }
  },
  computed: {
    query() {
      this.curID = Number(this.$route.query.imgId)
      return this.$route.query
    }
  }
}
</script>
<style lang='scss' scoped>
// element 容器的样式
.el-timeline {
  padding-left: 15px;
  /deep/ .el-timeline-item__wrapper {
    top: -1px;
  }
}
.tree-line {
  .tree-item {
    .text {
      cursor: pointer;
    }
  }
  .high-item {
    .text {
      color: #0486fe;
    }
  }
}
.tip {
  text-align: center;
  font-size: 14px;
  color: #909399;
  margin: 10px;
}
</style>