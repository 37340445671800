<template>
  <div
    class="pdf-page"
    v-if="fileInfo"
  >
    <div class="content g-ct-cn">
      <div class="doc-detail">
        <PdfInfo
          :fileInfo="fileInfo"
          :showSummary="false"
        />
      </div>
      <div class="pdf-cn">
        <PdfViewCn
          v-if="showPdf"
          :fileInfo="fileInfo"
          @pdf-rendered="pdfRendered = true"
        >
        </PdfViewCn>
      </div>
    </div>
    <Directory :pdfRendered="pdfRendered"></Directory>
  </div>
</template>

<script>
import { request } from '@/utils'
import { PdfViewCn, Directory } from './components/index'
import { PdfInfo } from '@/components'
export default {
  components: {
    PdfInfo,
    PdfViewCn,
    Directory
  },
  data () {
    return {
      fileInfo: null,
      pdfRendered: false,
      showPdf: false
    }
  },
  created() {
    this.getPdfInfo()
    this.getPermission()
  },
  methods: {
    // 为了权限专门调用的接口
    getPermission() {
      request({
        url: '/irapi/report/permission',
        method: 'get',
        params: {
          report_id: this.query.id
        }
      }).then(() => {
        this.showPdf = true
      }).catch(res => {
        this.showPdf = false
      })
    },
    getPdfInfo() {
      request({
        url: '/irapi/report',
        method: 'get',
        params: {
          id: this.query.id
        }
      }).then((res) => {
        this.fileInfo = res.data.datas[0]
      })
    },
  },
  computed: {
    query() {
      return this.$route.query
    }
  }
}
</script>
<style lang='scss' scoped>
.pdf-page {
  display: flex;
  margin: auto;
  .content {
    flex: 1;
    padding: 30px 30px;
    box-sizing: border-box;
    margin-right: 20px;
    overflow: hidden;
    .doc-detail {
      margin-bottom: 25px;
    }
    .pdf-cn {
      border: 1px solid var(--color-border-sec);
      width: 100%;
      height: calc(100vh - 60px);
      overflow: auto;
      /deep/ .pdf-wrap .view-wrap {
        background: var(--color-bg-html);
        overflow: hidden;
      }
    }
  }
}
</style>
<style>
body {
  height: auto;
}
</style>