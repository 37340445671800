<!--
  富文本标注高亮
  包含 渲染HTML、标注高亮和删除高亮、标注工具库
-->
<template>
  <div
    id="pdf2html"
    v-loading="loading"
  >
    <PdfView
      v-if="fileInfo"
      :directoryShow="false"
      :htmlJSON="htmlJSON"
      :tableJSON="tableJSON"
      :filePath="fileInfo.file_path + '?report_id=' + $route.query.id"
      :baseUrl="fileInfo.json_path + '/images/'"
      @pdfRendered="pdfRendered"
      @pdfPageRender="pdfPageRender"
      @changeZoom="changeZoom"
      :showConfig="{
        directoryShow: false,
        tableType: 'copy',
        imageShow: true,
      }"
    ></PdfView>
  </div>
</template>
<script type="text/javascript">
import { serviceNoInter } from '@/utils/index'
import PdfView from '@dl-components/pdf-view/dist/index.js'
import '@dl-components/pdf-view/dist/index.css'

export default {
  components: {
    PdfView,
  },
  props: {
    fileInfo: Object,
  },
  data() {
    return {
      htmlJSON: null,
      tableJSON: [],
      html: '',
      loading: true,
    }
  },
  created() {
    this._getDocData()
  },
  methods: {
    /**
     * PDF单页面渲染后执行的函数
     */
    pdfPageRender(num, textLayerDiv, viewport) {
    },
    pdfRendered() {
      this.html = 'pdf已经渲染出来了'
      this._canSkipToPage()
    },
    changeZoom(scale) {
      this.$emit('change-zoom', scale)
    },
    _getDocData() {
      serviceNoInter({
        url: '/irapi/report/doc.json',
        method: 'get',
        params: {
          report_id: this.$route.query.id
        },
      }).then((res) => {
        this.htmlJSON = res.data
        this._canSkipToPage()
      })
      serviceNoInter({
        url: '/irapi/report/table.json',
        method: 'get',
        params: {
          report_id: this.$route.query.id
        },
      }).then((res) => {
        this.tableJSON = res.data.pages
      })
    },
    _canSkipToPage() {
      if (this.html && this.htmlJSON) {
        this.$nextTick(() => {
          this.$emit('pdf-rendered')
          this.loading = false
        })
      }
    }
  },
  watch: {
    fileInfo() {
    },
  },
}
</script>
<style scoped lang="scss">
#pdf2html {
  height: 100%;
  flex: 1;
  overflow: auto;
}
.text {
  margin: 0 0 20px 40px;
  padding: 20px 35px;
  line-height: 2;
  background-color: white;
  font-family: serif;
  font-size: 18px;
  border-radius: 8px;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: auto;
}
</style>
